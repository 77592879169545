import { Form, Input, message, Select, Spin } from "antd";

import React, { useState } from "react";
import Button from "../../components/Button";
import { AllLeadership, UpdateLeadership, createLeadership } from "./service";
import { useDispatch, useSelector } from "react-redux";
import { setLeadershipLoading } from "../../actions/LeadershipActions";

export const AddLeadershipForm = () => {
  const [form] = Form.useForm();
  const state = useSelector((state) => state.leadershipState);
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (values) => {
    dispatch(setLeadershipLoading(true));
    setLoading(true);
    await createLeadership(dispatch, values, image);
    await AllLeadership(dispatch);
    dispatch(setLeadershipLoading(false));
    setLoading(false);
  };
  const handleEdit = async (values) => {
    dispatch(setLeadershipLoading(true));
    setLoading(true);
    await UpdateLeadership(dispatch, values, state?.viewModal?.data?.id);
    await AllLeadership(dispatch);
    dispatch(setLeadershipLoading(false));
    setLoading(false);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  return (
    <Spin spinning={loading}>
      <div className="flex flex-col items-center justify-center items-center text-xl">
        {!state?.viewModal?.visible && <span>Add New Leadership</span>}
        {state?.viewModal?.visible && <span>Edit Leadership</span>}
        <Form
          onFinish={state?.viewModal?.visible ? handleEdit : handleSubmit}
          form={form}
          layout="vertical"
          style={{ width: "85%", marginTop: "50px" }}
          initialValues={{
            name: state?.viewModal?.visible ? state?.viewModal?.data?.name : "",
            designation: state?.viewModal?.visible
              ? state?.viewModal?.data?.designation
              : "",
            color: state?.viewModal?.visible
              ? state?.viewModal?.data?.color
              : "",
            linkedIn_URL: state?.viewModal?.visible
              ? state?.viewModal?.data?.linkedIn_URL
              : "",
            status: state?.viewModal?.visible
              ? state?.viewModal?.data?.status
              : "",
          }}
        >
          <Form.Item
            name="name"
            label="Full Name"
            rules={[
              {
                required: true,
                message: `Name is required`,
              },
              {
                pattern: new RegExp(/^[A-Za-z\s]+$/),
                message: `Alphabet Only`,
              },
            ]}
          >
            <Input placeholder="FULL NAME" />
          </Form.Item>
          <Form.Item
            name="designation"
            label="designation"
            rules={[
              {
                required: true,
                message: `Designation is required`,
              },
            ]}
          >
            <Input placeholder="Designation" />
          </Form.Item>

          <Form.Item
            name="color"
            label="color"
            rules={[
              {
                required: true,
                message: `Color is required`,
              },
            ]}
          >
            <Input placeholder="Color" />
          </Form.Item>

          <Form.Item
            name="linkedIn_URL"
            label="linkedIn_URL"
            rules={[
              {
                required: true,
                message: `linkedIn_URL is required`,
              },
            ]}
          >
            <Input placeholder="LinkedIn_URL" />
          </Form.Item>

          <Form.Item
            name="calendly_url"
            label="Calendly_URL"
            rules={[
              {
                required: true,
                message: `Calendly_URL is required`,
              },
            ]}
          >
            <Input placeholder="Calendly_URL" />
          </Form.Item>

          <Form.Item
            name="status"
            label="Status"
            rules={[
              {
                required: true,
                message: `Status is required`,
              },
            ]}
          >
            <Select
              placeholder="Status"
              options={[
                { label: "Active", value: "active" },
                {
                  label: "Inactive",
                  value: "inactive",
                },
              ]}
            ></Select>
          </Form.Item>

          <Form.Item
            name="image"
            label="Image Upload"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <input
              name="image"
              type="file"
              accept="image/*"
              onChange={(e) => handleImageChange(e)}
            />
          </Form.Item>

          <Form.Item>
            <div>
              <Button
                label={
                  state?.viewModal?.visible
                    ? "Update Leadership"
                    : "Create Leadership"
                }
                type="submit"
                htmlType="submit"
              />
            </div>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};
