import React from "react";
import { Tooltip, Popconfirm, Checkbox } from "antd";
import { EyeOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { setJobLoading } from "../../actions/JobActions";
import { Activate, AllJobs, deleteJob } from "./service";
import { base_url } from "../../constants/constant";
import { extractDate } from "../Tickets/service";
import useProfileInfo from "../../hooks/UseProfile";
import { permissions_list } from "../../constants/StaticText";
export const Columns = (dispatch, navigate) => {
  const { userPermissions } = useProfileInfo();

  const handleActivate = async (object) => {
    const flag = object?.status === "published" ? true : false;
    dispatch(setJobLoading(true));
    await Activate(object, flag);
    await AllJobs(dispatch);
    dispatch(setJobLoading(false));
  };
  const handleDraft = async (object) => {
    const flag = object?.status === "draft" ? false : true;
    dispatch(setJobLoading(true));
    await Activate(object, flag);
    await AllJobs(dispatch);
    dispatch(setJobLoading(false));
  };
  return [
    {
      title: "Id",
      key: "1",
      width: 150,
      render: (_, object) => object?.id,
    },
    {
      key: "2",
      title: "Title",
      width: 150,
      render: (_, object) => object?.title,
    },
    // {
    //   key: "3",
    //   title: "Description",
    //   width: 150,
    //   render: (_, object) => object?.description,
    // },
    {
      key: "4",
      title: "Experience",
      width: 150,
      render: (_, object) => object?.experience,
    },
    {
      key: "4",
      title: "Type",
      width: 150,
      render: (_, object) => object?.job_type,
    },
    {
      key: "5",
      title: "Created By",
      width: 150,
      render: (_, object) => object?.created_by?.name,
    },

    {
      key: "6",
      title: "status",
      width: 150,
      render: (_, object) => (
        <Popconfirm
          okText="Yes"
          cancelText="No"
          title={
            object?.status === "draft"
              ? "Do You Want to Activate this JOB"
              : "Do You Want to Deactivate this JOB"
          }
          onConfirm={() => handleActivate(object)}
        >
          <Checkbox checked={object?.status === "draft" ? false : true} />
        </Popconfirm>
      ),
    },
    // {
    //     key:'5',
    //     title:"isdraft",
    //     width:150,
    //     render:(_,object)=>(
    //         <Popconfirm
    //             okText="Yes"
    //             cancelText="No"
    //             title={ object?.status==="draft" ? "Do You Want to Draft this JOB" :"Do You Want to Activate this JOB"}
    //             onConfirm={()=>handleDraft(object)}
    //         >
    //             <Checkbox
    //                 checked={object?.status==="draft"? false: true}
    //             />
    //         </Popconfirm>

    //     )

    // },
    {
      key: "4",
      title: "Location",
      width: 150,
      render: (_, object) => object?.location,
    },
    {
      key: "4",
      title: "Created At",
      width: 150,
      render: (_, object) => extractDate(object?.created_at),
    },
    {
      key: "4",
      title: "Updated At",
      width: 150,
      render: (_, object) => extractDate(object?.updated_at),
    },

    {
      fixed: "right",
      width: 150,
      title: "Actions",
      align: "center",
      render: (_, object, index) => (
        <>
          <Tooltip title="Detail">
            <a
              href={`${base_url}apply-now?id=${object?.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <EyeOutlined
                style={{
                  color: "blue",
                  cursor: "pointer",
                  fontSize: "20px",
                  paddingTop: "5px",
                }}
              />
            </a>
          </Tooltip>
          &nbsp; &nbsp;
          {userPermissions?.includes(permissions_list["UPDATE_JOBS"]) && (
            <Tooltip title="Edit Job">
              <EditOutlined
                style={{
                  color: "blue",
                  cursor: "pointer",
                  fontSize: "20px",
                  paddingTop: "5px",
                }}
                onClick={() => {
                  navigate("/job-post", {
                    state: {
                      id: object?.id,
                    },
                  });
                  // dispatch(setJobsViewMOdal({ visible: true, data: object }));
                  // dispatch(setAddJobsModal(true));
                }}
              />
            </Tooltip>
          )}
          &nbsp; &nbsp;
          {userPermissions?.includes(permissions_list["DELETE_JOBS"]) && (
            <Tooltip title="Delete Job">
              <Popconfirm
                style={{ zIndex: 10001 }}
                title="Are you sure to delete this Job?"
                onConfirm={async () => {
                  dispatch(setJobLoading(true));
                  await deleteJob(object?.id);
                  await AllJobs(dispatch);
                  dispatch(setJobLoading(false));
                }}
                okText="Yes"
                okType="primary"
                cancelText="No"
              >
                <DeleteOutlined
                  style={{
                    color: "red",
                    cursor: "pointer",
                    fontSize: "20px",
                    paddingTop: "5px",
                  }}
                />
              </Popconfirm>
            </Tooltip>
          )}
          &nbsp; &nbsp;
        </>
      ),
      key: "2",
    },
  ];
};
