import { Spin, Table } from "antd";
import React from "react";
import { Columns } from "./Columns";
import { useDispatch } from "react-redux";
import { AllUsers } from "./service";
import { FilterForm } from "./FilterForm";
import { useQuery } from "react-query";
import useProfileInfo from "../../hooks/UseProfile";

const Users = () => {
  const dispatch = useDispatch();

  const {
    data: UsersData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["users"],
    queryFn: () => AllUsers(),
    select: (res) => res?.data,
  });

  const columns = Columns(dispatch, refetch);
  return (
    <>
      <div>
        <Spin spinning={!!isLoading}>
          <FilterForm refetch={refetch} />
          <Table
            columns={columns}
            dataSource={UsersData?.users}
            pagination={{
              pageSize: 10,
            }}
            scroll={{ x: 1300, y: 660 }}
          />
        </Spin>
      </div>
    </>
  );
};

export default Users;
