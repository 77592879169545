import { Form, Input, message, Select } from "antd";
import React from "react";
import Button from "../../components/Button";
import { UpdateUser, createUser, fetchRoles } from "./service";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { useMutation, useQuery } from "react-query";
import { setAddUserModal } from "../../actions/UserActions";

export const AddUserForm = ({ refetch }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.userState);

  const { mutate, isLoading } = useMutation({
    mutationFn: (values) => createUser(values),
  });

  const { mutate: mutateUpdate, updateLoading } = useMutation({
    mutationFn: (values) => UpdateUser(values, state?.viewModal?.data?.id),
  });

  const { data: roles } = useQuery({
    queryKey: ["roles"],
    queryFn: () => fetchRoles(),
    select: (res) => res?.data,
  });

  const handleSubmit = async (values) => {
    mutate(values, {
      onSuccess: () => {
        refetch();
        dispatch(setAddUserModal(false));
      },
      onError: (err) => {
        message.error(err?.response?.data?.message);
      },
    });
  };
  const handleEdit = async (values) => {
    mutateUpdate(values, {
      onSuccess: () => {
        refetch();
      },
    });
  };

  return (
    <div className="flex flex-col items-center justify-center items-center text-xl">
      {!state?.viewModal?.visible && <span>Add New User</span>}
      {state?.viewModal?.visible && <span>Edit User</span>}

      <Form
        onFinish={state?.viewModal?.visible ? handleEdit : handleSubmit}
        form={form}
        layout="vertical"
        style={{ width: "85%", marginTop: "50px" }}
        initialValues={{
          name: state?.viewModal?.visible ? state?.viewModal?.data?.name : "",
          username: state?.viewModal?.visible
            ? state?.viewModal?.data?.email
            : "",
          role: state?.viewModal?.visible ? state?.viewModal?.data?.role : "",
        }}
      >
        <Form.Item
          name="name"
          label="Full Name"
          rules={[
            {
              required: true,
              message: `Name is required`,
            },
            {
              pattern: new RegExp(/^[A-Za-z\s]+$/),
              message: `Alphabet Only`,
            },
          ]}
        >
          <Input placeholder="FULL NAME" />
        </Form.Item>
        <Form.Item
          name="username"
          label="Username"
          rules={[
            {
              required: true,
              message: `Username is required`,
            },
          ]}
        >
          <Input placeholder="Username" />
        </Form.Item>

        <Form.Item
          name="roles"
          label="Role"
          rules={[
            {
              required: true,
              message: `Role is required`,
            },
          ]}
        >
          <Select
            // mode="multiple"
            placeholder="Select any role"
          >
            {roles?.map((role) => (
              <Select.Option key={role?.name} value={role?.id}>
                {role?.name?.charAt(0).toUpperCase() + role?.name?.slice(1)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <div>
            <Button
              label={state?.viewModal?.visible ? "Update User" : "Create User"}
              type="submit"
              htmlType="submit"
              loading={!!isLoading || !!updateLoading}
            />
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
