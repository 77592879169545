/** @format */

export const start_a_project =
  "Start a project with us or take existing one to next level";
export const share_your_challenge =
  "Share your challenge and our team will support you on a journey to deliver a revolutionary digital product";
export const our_leaderShip = "Our Leadership";
export const grow_business =
  "Grow Your Online Business Strategically, and Improve Customer Retention";
export const all_right = "© 2023 All rights reserved";
export const Discover_your_potential =
  "Discover Your Potential with Our Software Development Careers";
export const join_our_team1 =
  "Join our team of innovative software developers ";
export const join_our_team2 = "and shape the future of technology!";
export const core_values = "Our Core Values";
export const core_values_details =
  "These values are our guiding principles that shape the culture, behaviour, and decision-making of our organization. By embodying these core values, PikesSoft builds a strong brand reputation, attracts top talent, and achieves long-term success.";

export const please_enter_email_address = "Please enter email address";
export const email_min_length_err =
  "Email length must not be less than 5 characters";
export const email_max_length_err =
  "Email length must not be bigger than 255 characters";
export const email_starts_with_space_err = "Email must not start with space";
export const email_ends_with_dot_err = "Email must not end with '.'";
export const wrong_email_format = "Wrong email format";
export const empty_err = "Must not be empty";
export const values = "Value we bring to your project";
export const experties =
  "We cover wide range of expertise in digital world. We cover wide range of expertise in digital world.";
export const values_details =
  "We are committed to delivering exceptional results that exceed your expectations. We take the time to understand your business and its goals so we can create a customized digital strategy that delivers measurable results.";
// eslint-disable-next-line
export const emailRegex =
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u0041-\u005A\u0061-\u007A])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u0041-\u005A\u0061-\u007A])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u0041-\u005A\u0061-\u007A])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u0041-\u005A\u0061-\u007A]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u0041-\u005A\u0061-\u007A])|(([a-z]|\d|[\u0041-\u005A\u0061-\u007A])([a-z]|\d|-|\.|_|~|[\u0041-\u005A\u0061-\u007A])*([a-z]|\d|[\u0041-\u005A\u0061-\u007A])))\.)+(([a-z]|[\u0041-\u005A\u0061-\u007A])|(([a-z]|[\u0041-\u005A\u0061-\u007A])([a-z]|\d|-|\.|_|~|[\u0041-\u005A\u0061-\u007A])*([a-z]|[\u0041-\u005A\u0061-\u007A])))$/i;
export const Writings_from_our_team = "Writings from our team";
export const the_last_industry =
  " The latest industry news, interviews, technologies and resources.";
export const Policy_as_code =
  "Policy-as-code is a method of defining and managing security rules, criteria, and conditions through code.";
export const your_business =
  "We transform your business with custom software services";
export const marque =
  "PikesSoft at a glance • PikesSoft at a glance • PikesSoft at a glance • PikesSoft at a glance";
export const your_business_details =
  " We know the process, and empower the right team with the right skills to collaborate with you and deliver delightful solutions.";
export const why_pikessoft_details =
  "We are committed to delivering exceptional results that exceed your expectations. We take the time to understand your business and its goals so we can create a customized digital strategy that delivers measurable results.";
export const expert_software_development =
  "Expert software development services for your business";
export const we_streamLine =
  "We streamline your operations with our expertise with cutting-edge technological solutions";
export const we_build_text = "We build solid and scalable digital products";
export const with_in_depth_text =
  "With in-depth knowledge of the latest trends and technologies across industries, we consistently deliver solutions that drive business growth ";
export const we_design =
  "We design an outstanding web presence for all industry verticals ";
export const mobile_development = "Mobile Development Services";
export const mobile_development_detail =
  "End-to-end conceptualization, design, and support for your mobile app";
export const web_development = "Web Development Services";
export const product_design = "Product Design Services";
export const dev_ops = "DevOps Services";
export const quality_assurance = "Quality Assurance Services";

export const permissions = {
  delete_user_role_permissions: "Delete User Role Permissions",
  view_user_role_permissions: "View User Role Permissions",
  update_user_role_permissions: "Update User Role Permissions",
  create_user_role_permissions: "Create User Role Permissions",
  delete_role_permissions: "Delete Role Permissions",
  view_role_permissions: "View Role Permissions",
  update_role_permissions: "Update Role Permissions",
  create_role_permissions: "Create Role Permissions",
  delete_tickets: "Delete Tickets",
  view_tickets: "View Tickets",
  update_tickets: "Update Tickets",
  create_tickets: "Create Tickets",
  delete_roles: "Delete Roles",
  view_roles: "View Roles",
  update_roles: "Update Roles",
  create_roles: "Create Roles",
  delete_pictures: "Delete Pictures",
  view_pictures: "View Pictures",
  update_pictures: "Update Pictures",
  create_pictures: "Create Pictures",
  delete_permissions: "Delete Permissions",
  view_permissions: "View Permissions",
  update_permissions: "Update Permissions",
  create_permissions: "Create Permissions",
  delete_jobs: "Delete Jobs",
  view_jobs: "View Jobs",
  update_jobs: "Update Jobs",
  create_jobs: "Create Jobs",
  delete_features: "Delete Features",
  view_features: "View Features",
  update_features: "Update Features",
  create_features: "Create Features",
  delete_blogs: "Delete Blogs",
  view_blogs: "View Blogs",
  update_blogs: "Update Blogs",
  create_blogs: "Create Blogs",
  delete_auth: "Delete Auth",
  view_auth: "View Auth",
  update_auth: "Update Auth",
  create_auth: "Create Auth",
  delete_apply_jobs: "Delete Apply Jobs",
  view_apply_jobs: "View Apply Jobs",
  update_apply_jobs: "Update Apply Jobs",
  create_apply_jobs: "Create Apply Jobs",
  delete_users: "Delete Users",
  view_users: "View Users",
  update_users: "Update Users",
  create_users: "Create Users",
  delete_leadership: "Delete Leadership",
  view_leadership: "View Leadership",
  update_leadership: "Update Leadership",
  create_leadership: "Create Leadership",
  delete_developer: "Delete Developer",
  view_developer: "View Developer",
  update_developer: "Update Developer",
  create_developer: "Create Developer",
};

export const permissions_list = {
  DELETE_USER_ROLE_PERMISSIONS: "delete_user_role_permissions",
  VIEW_USER_ROLE_PERMISSIONS: "view_user_role_permissions",
  UPDATE_USER_ROLE_PERMISSIONS: "update_user_role_permissions",
  CREATE_USER_ROLE_PERMISSIONS: "create_user_role_permissions",
  DELETE_ROLE_PERMISSIONS: "delete_role_permissions",
  VIEW_ROLE_PERMISSIONS: "view_role_permissions",
  UPDATE_ROLE_PERMISSIONS: "update_role_permissions",
  CREATE_ROLE_PERMISSIONS: "create_role_permissions",
  DELETE_TICKETS: "delete_tickets",
  VIEW_TICKETS: "view_tickets",
  UPDATE_TICKETS: "update_tickets",
  CREATE_TICKETS: "create_tickets",
  DELETE_ROLES: "delete_roles",
  VIEW_ROLES: "view_roles",
  UPDATE_ROLES: "update_roles",
  CREATE_ROLES: "create_roles",
  DELETE_PICTURES: "delete_pictures",
  VIEW_PICTURES: "view_pictures",
  UPDATE_PICTURES: "update_pictures",
  CREATE_PICTURES: "create_pictures",
  DELETE_PERMISSIONS: "delete_permissions",
  VIEW_PERMISSIONS: "view_permissions",
  UPDATE_PERMISSIONS: "update_permissions",
  CREATE_PERMISSIONS: "create_permissions",
  DELETE_JOBS: "delete_jobs",
  VIEW_JOBS: "view_jobs",
  UPDATE_JOBS: "update_jobs",
  CREATE_JOBS: "create_jobs",
  DELETE_FEATURES: "delete_features",
  VIEW_FEATURES: "view_features",
  UPDATE_FEATURES: "update_features",
  CREATE_FEATURES: "create_features",
  DELETE_BLOGS: "delete_blogs",
  VIEW_BLOGS: "view_blogs",
  UPDATE_BLOGS: "update_blogs",
  CREATE_BLOGS: "create_blogs",
  DELETE_AUTH: "delete_auth",
  VIEW_AUTH: "view_auth",
  UPDATE_AUTH: "update_auth",
  CREATE_AUTH: "create_auth",
  DELETE_APPLY_JOBS: "delete_apply_jobs",
  VIEW_APPLY_JOBS: "view_apply_jobs",
  UPDATE_APPLY_JOBS: "update_apply_jobs",
  CREATE_APPLY_JOBS: "create_apply_jobs",
  DELETE_USERS: "delete_users",
  VIEW_USERS: "view_users",
  UPDATE_USERS: "update_users",
  CREATE_USERS: "create_users",
  DELETE_LEADERSHIP: "delete_leadership",
  VIEW_LEADERSHIP: "view_leadership",
  UPDATE_LEADERSHIP: "update_leadership",
  CREATE_LEADERSHIP: "create_leadership",
  DELETE_DEVELOPER: "delete_developer",
  VIEW_DEVELOPER: "view_developer",
  UPDATE_DEVELOPER: "update_developer",
  CREATE_DEVELOPER: "create_developer",
  VIEW_JOB_APPLICATION: "view_job_applications",
};
