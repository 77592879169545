import React, { useState } from "react";
import Button from "../../components/Button";
import { Col, Form, Input, Modal, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddUserModal,
  setUserLoading,
  setUserViewMOdal,
} from "../../actions/UserActions";
import { AddUserForm } from "./AddUserForm";
import { AllUsers, filter } from "./service";
import useProfileInfo from "../../hooks/UseProfile";
import { permissions_list } from "../../constants/StaticText";
export const FilterForm = ({ refetch }) => {
  const [form] = Form.useForm();
  const [search, setSearch] = useState("");
  const { userPermissions } = useProfileInfo();

  const dispatch = useDispatch();
  const userState = useSelector((state) => state?.userState);
  const handleAddUser = () => {
    dispatch(setAddUserModal(true));
  };
  const handleCloseAddUser = () => {
    if (userState?.viewModal?.visible) {
      dispatch(setAddUserModal(false));
      dispatch(setUserViewMOdal({ visible: false, data: {} }));
    } else {
      dispatch(setAddUserModal(false));
    }
  };
  const handleSearch = async (values) => {
    dispatch(setUserLoading(true));
    await filter(dispatch, values?.search);
    dispatch(setUserLoading(false));
  };
  const handleReset = async () => {
    setSearch("");
    form.resetFields(["search"]);
    dispatch(setUserLoading(true));
    await AllUsers(dispatch);
    dispatch(setUserLoading(false));
  };

  return (
    <>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
          }}
        >
          <Form onFinish={handleSearch} form={form} style={{ display: "flex" }}>
            <Form.Item
              name="search"
              rules={[
                {
                  required: true,
                  message: "required field for search",
                },
              ]}
            >
              <Input
                placeholder="Search"
                style={{
                  width: "300px",
                  marginRight: "50px",
                }}
                bordered={true}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <div type="submit">
                <Button label="Search" />
              </div>
            </Form.Item>
          </Form>
          {search && (
            <div onClick={handleReset} style={{ marginLeft: "10px" }}>
              <Button label="Reset" />
            </div>
          )}
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {userPermissions?.includes(permissions_list["CREATE_USERS"]) && (
            <div onClick={handleAddUser}>
              <Button label="Add User" />
            </div>
          )}
        </Col>
      </Row>
      <Modal
        open={userState?.addUserModal}
        onCancel={handleCloseAddUser}
        footer={null}
        destroyOnClose={true}
      >
        <AddUserForm refetch={refetch} />
      </Modal>
    </>
  );
};
