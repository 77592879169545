import { Button, Form, Input } from "antd";
import React from "react";
import "./style.css";
import { Text } from "../../components/Text";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { updatePassword } from "../AuthScreens/Login/service";
export const Password = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const param = new URLSearchParams(window.location.search);
  const email = param.get("email");
  const { mutate, isLoading } = useMutation({
    mutationFn: (data) => updatePassword(data),
  });
  const onFinish = (values) => {
    const body = {
      email: email,
      password: values?.password,
      isActive: true,
    };
    mutate(body, {
      onSuccess: () => {
        navigate("/");
      },
    });
  };
  return (
    <div className="password-screen">
      <div className="heading">
        <Text
          className="font-figtree text-black_900 pb-[30px] text-center tracking-[-1.28px] w-auto"
          variant="h2"
        >
          Welcome to Pikessoft
        </Text>
      </div>
      <div className="heading">
        <span className="font-figtree subHeading">
          Please Set your Password
        </span>
      </div>
      <div>
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          style={{
            maxWidth: 600,
          }}
          layout="vertical"
          scrollToFirstError
        >
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
            hasFeedback
          >
            <Input.Password className="input-styling" />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The new password that you entered do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password className="input-styling" />
          </Form.Item>
          <Form.Item>
            <Button
              loading={!!isLoading}
              htmlType="submit"
              className=" font-spacemono submit-button"
            >
              Set Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
