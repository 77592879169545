import { Routes, Route } from "react-router-dom";
import Users from "./screens/Users/Users";
import Leadership from "./screens/Leadership/Leadership";
import Developer from "./screens/Developer/Developer";
import { Jobs } from "./screens/Jobs/Jobs";
import AddBlog from "./screens/Blogs/AddBlog";
import { Tickets } from "./screens/Tickets/Tickets";
import { Applications } from "./screens/JobApplications/Application";
import { Admin } from "./screens/Admin/Admin";
import Blogs from "./screens/Blogs/Blogs";
import Pictures from "./screens/Pictures/Pictures";
import AddJob from "./screens/Jobs/AddJob";
import Roles from "./screens/Features/Role";
import { Password } from "./screens/setPassword/Password";

export const ContentRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Users />} />
      <Route path="/roles" element={<Roles />} />
      <Route path="/dashboard" element={<Admin />} />
      <Route path="/jobs" element={<Jobs />} />
      <Route path="/job-post" element={<AddJob />} />
      <Route path="/blog-post" element={<AddBlog />} />
      <Route path="/tickets" element={<Tickets />} />
      <Route path="/applications" element={<Applications />} />
      <Route path="/leadership" element={<Leadership />} />
      <Route path="/developers" element={<Developer />} />
      <Route path="/blog-listing" element={<Blogs />} />
      <Route path="pictures" element={<Pictures />} />
    </Routes>
  );
};
