import React, { useState } from "react";
import Button from "../../components/Button";
import { Col, Input, Modal, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddJobsModal,
  setJobLoading,
  setJobsViewMOdal,
} from "../../actions/JobActions";
import { filter } from "./service";
import { AddJob } from "./AddJob";

export const FilterForm = () => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const jobState = useSelector((state) => state?.jobState);
  const handleCloseAddUser = () => {
    if (jobState?.viewModal?.visible) {
      dispatch(setAddJobsModal(false));
      dispatch(setJobsViewMOdal({ visible: false, data: {} }));
    } else {
      dispatch(setAddJobsModal(false));
    }
  };
  const handleSearch = async () => {
    dispatch(setJobLoading(true));
    await filter(dispatch, search);
    dispatch(setJobLoading(false));
  };

  return (
    <>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
          }}
        >
          <Input
            placeholder="Search"
            style={{
              width: "300px",
              marginRight: "50px",
            }}
            bordered={true}
            onChange={(e) => setSearch(e?.target?.value)}
          />
          <div onClick={handleSearch}>
            <Button label="Search" />
          </div>
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        ></Col>
      </Row>
      <Modal
        open={jobState?.addModal}
        onCancel={handleCloseAddUser}
        footer={null}
        destroyOnClose={true}
      >
        <AddJob />
      </Modal>
    </>
  );
};
