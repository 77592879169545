import axios from "axios";
import { setAddBlogsModal, setBlogsArray, setTotalBlogs } from "../../actions/BlogActions";
import { message } from "antd";
import {setAddJobsModal} from "../../actions/JobActions";

const accessToken = JSON.parse(localStorage.getItem("userData"));

export const AllBlogs = async (dispatch) => {
    await axios
        .get(`${process.env.REACT_APP_API_URL}/api/blog/all`, {
            headers: {
                "ngrok-skip-browser-warning": true,
                Authorization: "Bearer " + accessToken.access_token,
            },
        })
        .then((data) => {
            dispatch(setBlogsArray(data?.data?.content));
            dispatch(setTotalBlogs(data?.data?.count));
        })
        .catch((error) => {
            message.error(`something went wrong`);
        });
};
export const ActiveBlogs = async (dispatch) => {
    await axios
        .get(`${process.env.REACT_APP_API_URL}/api/blog/activeblogs`, {
            headers: {
                "ngrok-skip-browser-warning": true,
                Authorization: "Bearer " + accessToken.access_token,
            },
        })
        .then((data) => {
            dispatch(setBlogsArray(data?.data?.content));
            dispatch(setTotalBlogs(data?.data?.count));
        })
        .catch((error) => {
            message.error(`something went wrong`);
        });
};


export const UpdateBlog=async(dispatch,values,id,image)=>{
    const payload = {
        title: values.title,
        content: values.content,
        image: image,

    };
    await axios.patch(`${process.env.REACT_APP_API_URL}/api/blog/${id}`,
        payload,
        {
            headers:{
                "ngrok-skip-browser-warning": true,
                Authorization: "Bearer " + accessToken.access_token,

            }
        }
    )
        .then((response)=>{
            dispatch(setAddBlogsModal(false));
            message.success("User Updated Successfully")
        })
        .catch((error)=>{
            message.error("User Updated Error")
        })


}
export const createBlog = async (dispatch, values, image) => {
    const payload = {
        title: values.title,
        image:image,
        content: values.content,
        status:values.status
    }


    await axios
        .post(`${process.env.REACT_APP_API_URL}/api/blog/create`, payload, {
            headers: {
                "ngrok-skip-browser-warning": true,
                Authorization: "Bearer " + accessToken.access_token,
            },
        })
        .then((data) => {
            dispatch(setAddJobsModal(false));
            message.success("User created successfully");
        })

        .catch((error) => {
            message.error(`something went wrong`);
        });
};

export const Activate=async (object,flag)=>{
    let status = flag ? "draft" : "published" ;
    const payload = {
        status,
    };
    await axios.patch(`${process.env.REACT_APP_API_URL}/api/blog/${object?.id}`,
        payload,
        {
            headers:{
                "ngrok-skip-browser-warning": true,
                Authorization: "Bearer " + accessToken.access_token,

            }
        }
    )
        .then((response)=>{
            message.success("Blog Updated Successfully")
        })
        .catch((error)=>{
            message.error("Blog Updated Error")
        })



}

export const deleteBlog=async (dispatch, id) => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/api/blog/${id}`,
        {
            headers:{
                "ngrok-skip-browser-warning": true,
                Authorization: "Bearer " + accessToken.access_token,

            }
        }
    )
        .then((response)=>{
            message.success("Blog Deleted Successfully")
        })
        .catch((error)=>{
            message.error("Blog Deleted Error")
        })

}

export const filter = async (dispatch,string) => {
    await axios
        .get(`${process.env.REACT_APP_API_URL}/api/blog/all?search=${string}`, {
            headers: {
                "ngrok-skip-browser-warning": true,
                Authorization: "Bearer " + accessToken.access_token,
            },
        })
        .then((data) => {
            dispatch(setBlogsArray(data?.data?.content));
            dispatch(setTotalBlogs(data?.data?.count));
        })
        .catch((error) => {
            message.error(`something went wrong`);
        });
};
