import { message } from "antd";
import axios from "axios";
import {
  setTotalUsers,
  setUserArray,
  setUserFeatures,
  setUserPermissions,
} from "../../actions/UserActions";
import Axios from "../../utils/middleware";
import { api_base_url } from "../../constants/constant";

const accessToken = JSON.parse(localStorage.getItem("userData"));

export const createUser = async (values) => {
  const payload = {
    email: values.username,
    name: values.name,
    roles: values.roles,
  };

  await Axios.post(`${api_base_url}/api/users/create`, payload);
};

export const AllUsers = () => {
  return Axios.get(`${api_base_url}/api/users/all`);
};

export const UpdateUser = async (dispatch, values, id) => {
  const payload = {
    email: values.username,
    name: values.name,
    role: values.role,
  };
  return Axios.patch(`${api_base_url}/api/users/${id}`, payload);
};

// export const fetchPermissions = async (dispatch) => {
//   await axios
//     .get(`${process.env.REACT_APP_API_URL}/api/permissions`, {
//       headers: {
//         "ngrok-skip-browser-warning": true,
//         Authorization: "Bearer " + accessToken.access_token,
//       },
//     })
//     .then((response) => {
//       const permissions = response?.data?.map((perm) => ({
//         id: perm.id,
//         name: perm.name,
//       }));
//       dispatch(setUserPermissions(permissions));
//     })
//     .catch((error) => {
//       const errorMessage = error?.response?.data?.message;
//       message.error(`${errorMessage}`);
//     });
// };

export const fetchFeatures = async (dispatch) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/features/all`, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((response) => {
      const features = response?.data?.content.map((feature) => ({
        id: feature.id,
        feature_name: feature.feature_name,
      }));
      dispatch(setUserFeatures(features));
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.message;
      message.error(`${errorMessage}`);
    });
};

export const fetchRoles = () => {
  return Axios.get(`${api_base_url}/api/roles`);
};

export const deleteUser = (id) => {
  return Axios.delete(`${api_base_url}/api/users/${id}`);
};

export const filter = async (dispatch, string) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/users/all?search=${string}`, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((data) => {
      dispatch(setUserArray(data?.data?.users));
      dispatch(setTotalUsers(data?.data?.count));
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.message;
      message.error(`${errorMessage}`);
    });
};
