import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  userQueryParams: {
    skip: 0,
    take: 10,
    currentPage: 1,
    name: null,
  },
  profileData: {
    profileName: "",
    email: "",
    roles: [],
    permissions: [],
    id: null,
    userLanguage: "en",
  },
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setProfileData: (state, action) => {
      state.profileData = action.payload;
    },
    setUserQueryParams: (state, action) => {
      state.userQueryParams = action.payload;
    },
    resetUserQueryParams: (state) => {
      state.userQueryParams = initialState?.userQueryParams;
    },
  },
});

export const { setProfileData, setUserQueryParams, resetUserQueryParams } =
  userSlice.actions;

export default userSlice.reducer;
